<template>
  <div
    class="product-card product-card--ref-co"
    :class="`product-card--${prismicProduct.uid} product-card--${prismicProduct.marketType}`"
  >
    <nuxt-link
      :to="
        $contextPrismicPath({
          type: 'product',
          uid: prismicProduct.uid,
        })
      "
      class="d-block p-relative"
      @click.native="loading = true"
    >
      <div class="product-card-v2__image">
        <transition mode="in-out" name="product-card-image-switch">
          <lazy-image
            :images="images"
            :preload="isFirst"
            :force-show="isFirst"
            sizes="100vw"
          />
        </transition>
      </div>

      <div
        @mouseover="hovering = true"
        @mouseleave="
          hovering = false
          activeColor = -1
        "
      >
        <div
          v-waypoint="{
            active: !contentAnimated && timeline.initiated,
            callback: onContentWaypoint,
            options: intersectionOptions,
          }"
          class="product-card__content"
        >
          <p v-if="showNextBatch" ref="tag" class="tag tag--sun-and-white">
            <icon name="estimated-time" />
            <span>{{ $t('product.tag.last_chance') }}</span>
          </p>

          <p v-else-if="thisWeekOnly" class="tag tag--sun-and-white">
            <icon name="estimated-time" />
            <span> {{ $t('preco_of_the_week') }}</span>
          </p>

          <div
            ref="productName"
            class="product-name mb-0-4 mb-md-0-6"
            v-html="titleHTML"
          />

          <rich-text
            v-if="hasPunchLine"
            ref="punchline"
            :content="prismicProduct.punchline"
            class="product-card__punchline h5 pl-1 pr-1 text-center text-color-tertiary"
          />

          <div ref="colors" class="d-flex justify-center">
            <transition
              v-if="distinctColors.length > 1"
              mode="out-in"
              @enter="colorEnter"
              @leave="colorLeave"
            >
              <div
                v-if="hovering"
                class="product-card__colors d-flex align-center flex-wrap"
              >
                <button
                  v-for="(color, index) in distinctColors"
                  :key="color.name"
                  type="button"
                  class="colors__matter"
                  :class="{ active: activeColor === index }"
                  :title="color.color_name"
                  @mouseenter="colorMatterMouseEnter(index)"
                  @click.prevent="goToColor(color.color_name)"
                >
                  <span
                    class="bg"
                    :style="`background-image: url(${getColorThumbnailBg(
                      color
                    )})`"
                  ></span>
                  <span class="visually-hidden">{{ color.color_name }}</span>
                </button>
              </div>
              <p v-else class="product-card__colors--number mb-0">
                <span style="line-height: 34px">
                  {{
                    $t('card.product.number-of-colors', {
                      number: distinctColors.length,
                    })
                  }}
                </span>
              </p>
            </transition>
          </div>

          <big-count-down
            v-if="showOverSoon"
            ref="countdown"
            :date="prismicProduct.dates.end"
            home
            class="pt-0-6 pt-md-1"
          />
        </div>
      </div>
    </nuxt-link>

    <div v-if="refCoList.length > 1" class="product-card__refs">
      <nuxt-link
        v-for="(color, index) of refCoList"
        :key="getKey(color, index)"
        :style="[gridItemsPosition[index], gridItemsPositionMobile[index]]"
        :to="prepareForQuery(color)"
        class="product-card__ref"
        :class="{
          'product-card__ref--refco': color.type === 'refco',
          'product-card__ref--edito': color.type === 'edito',
          'product-card__ref--video': color.type === 'video',
          [`product-card__ref--${color.edito_color}`]:
            color.type === 'edito' && color.edito_color,
        }"
      >
        <div v-if="color.type === 'refco'" class="product-card__ref__image">
          <lazy-image
            v-if="
              gridItemsPositionMobile[index] &&
              gridItemsPositionMobile[index].type === 'full'
            "
            :image="getImage(color, 'mobile')"
            :image-cdn="getImageCdn(color)"
            :widths="[390, 780, 1170]"
            sizes="100vw"
            class="d-block d-md-none"
          />
          <lazy-image
            v-else
            :image="getImage(color, 'list')"
            :image-cdn="getImageCdn(color)"
            :widths="[195, 390, 585]"
            sizes="50vw"
            class="d-block d-md-none"
          />

          <lazy-image
            v-if="
              gridItemsPosition[index] &&
              gridItemsPosition[index].type === 'full'
            "
            :image="getImage(color, 'highlight')"
            :image-cdn="getImageCdn(color)"
            :widths="[1600, 2400, 3200]"
            sizes="100vw"
            class="d-none d-md-block"
          />
          <lazy-image
            v-else-if="
              gridItemsPosition[index] &&
              gridItemsPosition[index].type === 'half'
            "
            :image="getImage(color, 'half')"
            :image-cdn="getImageCdn(color)"
            :widths="[720, 1440, 2160]"
            sizes="50vw"
            class="d-none d-md-block"
          />
          <lazy-image
            v-else
            :image="getImage(color, 'list')"
            :image-cdn="getImageCdn(color)"
            :widths="[480, 960, 1440]"
            sizes="33vw"
            class="d-none d-md-block"
          />
        </div>

        <div
          v-if="color.type === 'video'"
          class="d-flex flex-grow-1 product-card__ref__video"
        >
          <client-only>
            <!-- <vimeo-player
              :ref="`player${index}`"
              :video-id="color.id"
              :options="{
                muted: true,
                loop: true,
              }"
              class="flex-grow-1"
            /> -->

            <video-bunny
              :src="`https://iframe.mediadelivery.net/embed/${BUNNY_STREAM_FOLDERS.Snacks}/${color.id}?autoplay=true&loop=true&muted=true`"
            />
          </client-only>
        </div>

        <div class="product-card__ref__content">
          <div
            v-if="color.type === 'refco' || color.type === 'video'"
            class="inner d-md-flex align-end"
          >
            <div
              v-if="!hideTags && color.is_new"
              class="tag tag--yellow-and-secondary"
            >
              <icon name="new" />
              <span>{{ $t('product.tag.new_color') }}</span>
            </div>

            <div
              v-if="!hideTags && color.is_leaving_soon"
              class="tag tag--sun-and-white"
            >
              <icon name="estimated-time" />
              <span>{{ $t('product.tag.leaving_soon') }}</span>
            </div>

            <div>
              <p class="product-card__ref__name mb-0">
                {{ color.color_name }}
                <span v-if="color.linkedVariantName">
                  / {{ color.linkedVariantName }}
                </span>
                <span v-else-if="color.third_variant_name">
                  / {{ color.third_variant_name }}
                </span>

                <span class="product-card__ref__icon ml-0-6 mb-0-2 d-md-none">
                  <icon name="arrow-right" />
                </span>
              </p>
            </div>

            <div class="product-card__prices d-flex pt-0-2 pt-md-0">
              <p
                class="price d-flex flex-wrap align-baseline text-color-tertiary text-secondary mb-0 mr-0-6 mr-md-1"
              >
                <span class="number pr-0-4"
                  >{{ getColorPrice(color) }}&nbsp;&euro;</span
                >
                <span class="text-small text-no-wrap">{{
                  $t('product.in_preco')
                }}</span>
              </p>

              <p
                class="price compare d-flex flex-wrap align-baseline text-color-tertiary text-secondary mb-0"
              >
                <span class="number pr-0-4"
                  >{{ getColorPrice(color, true) }}&nbsp;&euro;</span
                >
                <span class="text-small text-no-wrap">{{
                  $t('product.in_stock')
                }}</span>
              </p>
            </div>

            <div class="product-card__ref__icon d-none d-md-block">
              <icon name="arrow-right" />
            </div>
          </div>
          <div
            v-else-if="color.type === 'edito'"
            class="product-card__ref__content"
          >
            <rich-text :content="color.edito" />
          </div>
        </div>
      </nuxt-link>
    </div>

    <div v-html="gridStyle"></div>
  </div>
</template>

<script>
import * as prismic from '@prismicio/client'
import { differenceInDays, isFuture, isSameWeek } from 'date-fns'

import LazyImage from '@/components/Image/LazyImage'
import RichText from '@/components/RichText/RichText'
import normalize, { normalizeUrl } from '@/utils/normalize'
import ShopifyProduct from '@/entities/shopify/Product'
import Icon from '@/components/icons/Icon'
import VideoBunny from '@/components/video/bunny'

import { BUNNY_STREAM_FOLDERS } from '@/const'
import services from '@/services'
import ShopifyBase64 from '@/utils/shopifyBase64'

import { fromPrismicImageConfToBunnyImageLink } from '~/utils/imageSize'

export default {
  name: 'ProductCardRefCo',
  components: {
    LazyImage,
    RichText,
    Icon,
    VideoBunny,
    BigCountDown: () =>
      import(
        '@/components/countdown/BigCountDown' /* webpackChunkName: 'big_countdown' */
      ),
  },
  props: {
    prismicProduct: {
      type: Object,
      required: true,
    },
    shopifyProduct: {
      type: Object,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    hideTags: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    numberOfLines: 0,
    gridTemplateRows: '',
    gridItemsPosition: [],
    numberOfLinesMobile: 0,
    gridTemplateRowsMobile: '',
    gridItemsPositionMobile: [],
    contentAnimated: false,
    intersectionOptions: {
      root: null,
      rootMargin: '150px 0px 0px 0px',
      threshold: [0, 1],
    },
    refsInScreen: [],
    windowHeight: 0,
    timeline: {
      initiated: false,
      content: null,
    },
    linkedShopifyProducts: {},
    activeColor: -1,
    hovering: false,
    BUNNY_STREAM_FOLDERS,
  }),
  head() {
    if (this.isFirst) {
      if (this.isOnHome) {
        if (this.prismicProduct.isTopOfImageLight) {
          return {
            htmlAttrs: {
              class: ['header--dark'],
            },
          }
        }
      } else if (prismic.isFilled.image(this.prismicProduct.eshopImage)) {
        if (this.prismicProduct.isEshopTopOfImageLight) {
          return {
            htmlAttrs: {
              class: ['header--dark'],
            },
          }
        }
      } else if (this.prismicProduct.isTopOfImageLight) {
        return {
          htmlAttrs: {
            class: ['header--dark'],
          },
        }
      }
    }
    return {}
  },
  computed: {
    titleHTML() {
      if (this.prismicProduct.nameFull) {
        return prismic
          .asHTML(this.prismicProduct.nameFull)
          .replaceAll('h1', 'h2')
      }

      return `<h2>${this.prismicProduct.name}</h2>`
    },
    isOnHome() {
      return this.$route.name.startsWith('context__')
    },
    images() {
      const imgs = []
      let image = this.prismicProduct.image
      let imageCdn = this.prismicProduct.imageCdn

      if (this.activeColor > -1) {
        const color = this.distinctColors[this.activeColor]
        if (color) {
          image = color.image_principale
          imageCdn = color.image_principale_cdn
        }
      } else if (
        prismic.isFilled.image(this.prismicProduct.eshopImage) &&
        !this.isOnHome
      ) {
        image = this.prismicProduct.eshopImage
        imageCdn = this.prismicProduct.imageEshopCdn
      }

      // MOBILE
      if (image.list && image.list.url) {
        imgs.push({
          image: image.list,
          imageCdn,
          widths: [380, 570, 760],
          media: {
            type: 'max',
            value: 769,
          },
        })
      }

      // DESKTOP
      if (image.highlight && image.highlight.url) {
        imgs.push({
          image: image.highlight,
          imageCdn,
          media: {
            type: 'min',
            value: 770,
          },
          widths: [1440, 2160, 2880],
          sizes: '100vw',
        })
      }

      return imgs
    },
    hasPunchLine() {
      return prismic.isFilled.richText(this.prismicProduct.punchline)
    },
    refCoList() {
      if (
        this.prismicProduct.orderAndRenderOfRefCo.length > 0 &&
        this.prismicProduct.orderAndRenderOfRefCo.some((o) => o.color_name)
      ) {
        return this.prismicProduct.orderAndRenderOfRefCo
          .map((refCo) => {
            if (refCo.is_edito && prismic.isFilled.richText(refCo.edito)) {
              return {
                type: 'edito',
                edito: refCo.edito,
                edito_color: refCo.edito_color,
              }
            }

            if (refCo.color_name) {
              if (refCo.from_linked_product) {
                if (
                  refCo.linked_product_label &&
                  this.prismicProduct.linkedProducts.length > 0
                ) {
                  const linkedProduct = this.prismicProduct.linkedProducts.find(
                    (p) => p.label === refCo.linked_product_label
                  )

                  if (linkedProduct) {
                    const c = linkedProduct.product.data.colors.find(
                      (color) => color.color_name === refCo.color_name
                    )
                    if (c) {
                      return {
                        type:
                          refCo.is_video &&
                          prismic.isFilled.keyText(refCo.video_id)
                            ? 'video'
                            : 'refco',
                        id: refCo.video_id,
                        isLinked: true,
                        uid: linkedProduct.product.uid,
                        shopifyId: linkedProduct.product.data.shopify_id,
                        linkedVariantName: linkedProduct.label,
                        override_image: refCo.override_image,
                        override_image_cdn: refCo.override_image_cdn,
                        ...c,
                      }
                    }
                  }
                }
              } else {
                const c = this.prismicProduct.colors.find(
                  (color) => color.color_name === refCo.color_name
                )
                if (c) {
                  return {
                    type:
                      refCo.is_video && prismic.isFilled.keyText(refCo.video_id)
                        ? 'video'
                        : 'refco',
                    id: refCo.video_id,
                    linkedVariantName: refCo.linked_product_label,
                    override_image: refCo.override_image,
                    override_image_cdn: refCo.override_image_cdn,
                    ...c,
                  }
                }
              }
            }

            return null
          })
          .filter((refCo) => refCo)
      }

      if (this.prismicProduct.linkedProducts.length > 0) {
        const currentLinkedVariantName =
          this.prismicProduct.linkedProducts.find(
            (p) => p.product && p.product.uid === this.prismicProduct.uid
          )

        let colors = this.prismicProduct.colors.map((color) => ({
          type: 'refco',
          linkedVariantName: currentLinkedVariantName
            ? currentLinkedVariantName.label
            : null,
          ...color,
        }))

        const linkedColors = this.prismicProduct.linkedProducts
          .filter(
            (p) =>
              p.product &&
              p.product.uid !== this.prismicProduct.uid &&
              p.product.data.colors.length > 0
          )
          .map((linkedProduct) => {
            return linkedProduct.product.data.colors.map((color) => ({
              type: 'refco',
              isLinked: true,
              uid: linkedProduct.product.uid,
              shopifyId: linkedProduct.product.data.shopify_id,
              linkedVariantName: linkedProduct.label,
              ...color,
            }))
          })

        for (const linkedColor of linkedColors) {
          colors = colors.concat(linkedColor)
        }

        return colors
      }

      return this.prismicProduct.colors.map((color) => ({
        type: 'refco',
        ...color,
      }))
    },
    gridStyle() {
      return `<style>
        .product-card--${this.prismicProduct.uid} .product-card__refs {
          --number-of-lines: ${this.numberOfLinesMobile};
          ${this.gridTemplateRowsMobile}

          @media screen and (min-width: 769px) {
            --number-of-lines: ${this.numberOfLines};
            ${this.gridTemplateRows}
          }
        }
      </style>`
    },
    showNextBatch() {
      if (
        this.prismicProduct.dates.currentBatchEnd &&
        this.prismicProduct.dates.nextDeliveryDate
      ) {
        const now = Date.now()
        const diff = this.prismicProduct.dates.currentBatchEnd.getTime() - now
        const nbDays = Math.ceil(diff / (1000 * 60 * 60 * 24))

        return (
          this.prismicProduct.dates.currentBatchEnd.getTime() > Date.now() &&
          nbDays <= 7
        )
      }
      return false
    },
    showOverSoon() {
      if (this.prismicProduct.dates.end) {
        return (
          isFuture(this.prismicProduct.dates.end) &&
          differenceInDays(this.prismicProduct.dates.end, new Date()) <= 10
        )
      }

      return false
    },
    thisWeekOnly() {
      if (this.prismicProduct.dates.start && this.showOverSoon) {
        return isSameWeek(
          this.prismicProduct.dates.start,
          this.prismicProduct.dates.end,
          {
            weekStartsOn: 1,
          }
        )
      }

      return false
    },
    distinctColors() {
      const cc = []

      for (const color of this.prismicProduct.colors) {
        if (!cc.some((c) => c.color_name === color.color_name)) {
          cc.push(color)
        }
      }

      return cc
    },
  },
  created() {
    if (process.server) {
      this.init()
    }
  },
  mounted() {
    this.init()
    this.initAnim()
    this.windowHeight = window.innerHeight

    this.linkedProductsInit()
  },
  methods: {
    init() {
      let full = false
      let lines = 0
      let matrixLineIndex = 0
      let numberOfPlaces = 0
      const gridLineHeight = []
      const gridItemsPosition = []

      while (!full) {
        const matrixLine = this.prismicProduct.refCoMatrix[matrixLineIndex]

        if (matrixLine && matrixLine.number_per_line) {
          lines++
          numberOfPlaces += matrixLine.number_per_line

          if (
            this.prismicProduct.orderAndRenderOfRefCo.length > 0 &&
            matrixLine.number_per_line === 1 &&
            this.prismicProduct.orderAndRenderOfRefCo[numberOfPlaces - 1] &&
            this.prismicProduct.orderAndRenderOfRefCo[numberOfPlaces - 1]
              .is_edito
          ) {
            gridLineHeight.push('auto')
          } else {
            gridLineHeight.push(
              this.getHeightOfLine(matrixLine.number_per_line)
            )
          }
          gridItemsPosition.push(
            ...this.getGridLineItemsPositions(matrixLine.number_per_line, lines)
          )

          // Fin de la boucle si on a atteint le nombre de couleurs
          if (this.refCoList.length <= numberOfPlaces) {
            full = true
          }

          // Boucle de la matrice
          if (
            this.prismicProduct.refCoMatrix[matrixLineIndex + 1] === undefined
          ) {
            matrixLineIndex = 0
          } else {
            matrixLineIndex++
          }

          continue
        }

        full = true
      }

      // AB-TEST : On affiche seulement la première ligne
      this.gridTemplateRows = `grid-template-rows: ${gridLineHeight[0]};`
      this.gridItemsPosition = gridItemsPosition
      this.numberOfLines = 1

      // this.gridTemplateRows = `grid-template-rows: ${gridLineHeight.join(' ')};`
      // this.gridItemsPosition = gridItemsPosition
      // this.numberOfLines = lines
      // END AB-TEST

      let fullMobile = false
      lines = 0
      matrixLineIndex = 0
      numberOfPlaces = 0
      const gridLineHeightMobile = []
      const gridItemsPositionMobile = []

      while (!fullMobile) {
        const matrixLine =
          this.prismicProduct.refCoMatrixMobile[matrixLineIndex]

        if (matrixLine && matrixLine.number_per_line) {
          lines++
          numberOfPlaces += matrixLine.number_per_line
          if (
            this.prismicProduct.orderAndRenderOfRefCo.length > 0 &&
            matrixLine.number_per_line === 1 &&
            this.prismicProduct.orderAndRenderOfRefCo[numberOfPlaces - 1] &&
            this.prismicProduct.orderAndRenderOfRefCo[numberOfPlaces - 1]
              .is_edito
          ) {
            gridLineHeightMobile.push('auto')
          } else {
            gridLineHeightMobile.push(
              this.getHeightOfLine(matrixLine.number_per_line)
            )
          }
          gridItemsPositionMobile.push(
            ...this.getGridLineItemsPositions(
              matrixLine.number_per_line,
              lines,
              true
            )
          )

          // Fin de la boucle si on a atteint le nombre de couleurs
          if (this.refCoList.length <= numberOfPlaces) {
            fullMobile = true
          }

          // Boucle de la matrice
          if (
            this.prismicProduct.refCoMatrixMobile[matrixLineIndex + 1] ===
            undefined
          ) {
            matrixLineIndex = 0
          } else {
            matrixLineIndex++
          }

          continue
        }

        fullMobile = true
      }

      // AB-TEST : On affiche seulement la première ligne
      this.gridTemplateRowsMobile = `grid-template-rows: ${gridLineHeightMobile[0]};`
      this.gridItemsPositionMobile = gridItemsPositionMobile
      this.numberOfLinesMobile = 1

      // this.gridTemplateRowsMobile = `grid-template-rows: ${gridLineHeightMobile.join(
      //   ' '
      // )};`
      // this.gridItemsPositionMobile = gridItemsPositionMobile
      // this.numberOfLinesMobile = lines

      // END AB-TEST
    },
    async initAnim() {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      this.timeline.content = gsap.timeline({
        onComplete: () => {
          this.contentAnimated = true
        },
      })

      let start = 0

      if (this.$refs.tag) {
        gsap.set(this.$refs.tag, {
          opacity: 0,
          scale: 0.8,
          y: -20,
        })

        this.timeline.content.to(
          this.$refs.tag,
          {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.8,
            ease: 'power2.out',
          },
          start
        )
        start += 0.2
      }

      if (this.$refs.productName) {
        gsap.set(this.$refs.productName, {
          opacity: 0,
          scale: 0.8,
          y: -20,
        })
        this.timeline.content.to(
          this.$refs.productName,
          {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.8,
            ease: 'power2.out',
          },
          start
        )
        start += 0.2
      }

      if (this.$refs.punchline && this.$refs.punchline.$el) {
        gsap.set(this.$refs.punchline.$el, {
          opacity: 0,
          scale: 0.8,
          y: -20,
        })
        this.timeline.content.to(
          this.$refs.punchline.$el,
          {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.8,
            ease: 'power2.out',
          },
          start
        )
      }

      if (this.$refs.colors) {
        gsap.set(this.$refs.colors, {
          opacity: 0,
          scale: 0.8,
          y: -20,
        })
        this.timeline.content.to(
          this.$refs.colors,
          {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.8,
            ease: 'power2.out',
          },
          start
        )
        start += 0.2
      }

      if (this.$refs.countdown && this.$refs.countdown.$el) {
        gsap.set(this.$refs.countdown.$el, {
          opacity: 0,
          scale: 0.8,
          y: -20,
        })
        this.timeline.content.to(
          this.$refs.countdown.$el,
          {
            opacity: 1,
            y: 0,
            scale: 1,
            duration: 0.8,
            ease: 'power2.out',
          },
          start
        )
      }

      this.timeline.content.pause()
      this.timeline.initiated = true
    },
    async linkedProductsInit() {
      if (
        this.prismicProduct.linkedProducts.length > 0 &&
        this.prismicProduct.linkedProducts.some(
          (p) => p.product && p.product.data.shopify_id
        )
      ) {
        const shopifyIds = this.prismicProduct.linkedProducts
          .filter((p) => p.product.uid !== this.prismicProduct.uid)
          .map((p) => p.product.data.shopify_id)

        const shopifyProducts =
          await services.productService.getShopifyProducts(
            shopifyIds,
            this.$i18n.locale
          )

        for (const shopifyProduct of shopifyProducts) {
          this.linkedShopifyProducts[ShopifyBase64.getId(shopifyProduct.id)] =
            new ShopifyProduct(shopifyProduct)
        }
      }
    },
    getHeightOfLine(numberOfProductsPerLine) {
      switch (numberOfProductsPerLine) {
        case 1:
          return 'var(--grid-line-height-1-p)'
        case 2:
          return 'var(--grid-line-height-2-p)'
        case 3:
          return 'var(--grid-line-height-3-p)'
        default:
          return 'var(--grid-line-height-3-p)'
      }
    },
    getGridLineItemsPositions(
      numberOfProductsPerLine,
      currentLine,
      mobile = false
    ) {
      switch (numberOfProductsPerLine) {
        case 1:
          // AB-TEST : On affiche seulement la première ligne
          if (currentLine > 1) {
            return [
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
            ]
          }
          // END AB-TEST
          return [
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '1 / 13',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'full',
            },
          ]
        case 2:
          // AB-TEST : On affiche seulement la première ligne
          if (currentLine > 1) {
            return [
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
            ]
          }
          // END AB-TEST
          return [
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '1 / 7',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'half',
            },
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '7 / 13',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'half',
            },
          ]
        case 3:
          // AB-TEST : On affiche seulement la première ligne
          if (currentLine > 1) {
            return [
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
            ]
          }
          // END AB-TEST
          return [
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '1 / 5',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'list',
            },
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '5 / 9',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'list',
            },
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '9 / 13',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'list',
            },
          ]
        case 4:
          // AB-TEST : On affiche seulement la première ligne
          if (currentLine > 1) {
            return [
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
              { [mobile ? '--display-mobile' : '--display-desktop']: 'none' },
            ]
          }
          // END AB-TEST
          return [
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '1 / 4',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'list',
            },
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '4 / 7',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'list',
            },
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '7 / 10',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'list',
            },
            {
              [mobile ? '--grid-column-mobile' : '--grid-column']: '10 / 13',
              [mobile
                ? '--grid-row-mobile'
                : '--grid-row']: `${currentLine} / ${currentLine + 1}`,
              type: 'list',
            },
          ]
        default:
          return []
      }
    },
    getKey(color, index) {
      if (color.type === 'edito') {
        return `edito-${index}`
      }

      if (color.type === 'video') {
        return `video-${index}`
      }

      if (color.linkedVariantName) {
        return `${color.color_name}${color.linkedVariantName}-${index}`
      }

      return `${color.color_name}${color.third_variant_name || ''}-${index}`
    },
    getColorPrice(color, compare = false) {
      if (
        !this.shopifyProduct ||
        !(this.shopifyProduct instanceof ShopifyProduct)
      ) {
        return null
      }

      if (
        color.isLinked &&
        (!this.linkedShopifyProducts[color.shopifyId] ||
          !(
            this.linkedShopifyProducts[color.shopifyId] instanceof
            ShopifyProduct
          ))
      ) {
        return null
      }

      if (compare) {
        if (color.isLinked) {
          return this.linkedShopifyProducts[
            color.shopifyId
          ].compareAtPriceByDimension('color', normalize(color.color_name))
        }
        return this.shopifyProduct.compareAtPriceByDimension(
          'color',
          normalize(color.color_name)
        )
      }

      if (color.isLinked) {
        return this.linkedShopifyProducts[color.shopifyId].priceByDimension(
          'color',
          normalize(color.color_name)
        )
      }

      return this.shopifyProduct.priceByDimension(
        'color',
        normalize(color.color_name)
      )
    },
    getColorThumbnailBg(color) {
      if (color.color_thumbnail_cdn) {
        return normalizeUrl(
          fromPrismicImageConfToBunnyImageLink(
            color.color_thumbnail_cdn,
            color.color_thumbnail
          )
        )
      }

      return color.color_thumbnail.url
    },
    colorMatterMouseEnter(index) {
      this.activeColor = index
    },
    goToColor(color) {
      if (this.soonInSell) {
        return
      }

      this.loading = true
      this.$router.push({
        path: this.$contextPrismicPath({
          type: 'product',
          uid: this.prismicProduct.uid,
        }),
        query: { ...this.params, color: normalize(color) },
      })
    },
    prepareForQuery(color) {
      if (color.type === 'edito' || color.type === 'video') {
        return {
          path: this.$contextPrismicPath({
            type: 'product',
            uid: this.prismicProduct.uid,
          }),
        }
      }

      if (color.isLinked) {
        return {
          path: this.$contextPrismicPath({
            type: 'product',
            uid: color.uid,
          }),
          query: {
            color: normalize(color.color_name),
          },
        }
      }

      return {
        path: this.$contextPrismicPath({
          type: 'product',
          uid: this.prismicProduct.uid,
        }),
        query: {
          color: normalize(color.color_name),
        },
      }
    },
    onContentWaypoint(waypoint) {
      if (waypoint.going === this.$waypointMap.GOING_IN) {
        if (this.timeline.content && !this.timeline.content.isActive()) {
          this.timeline.content.restart()
        }
      }
    },
    getImage(color, type) {
      if (
        prismic.isFilled.image(color.override_image) &&
        color.override_image[type] &&
        color.override_image[type].url
      ) {
        return color.override_image[type]
      }

      if (
        prismic.isFilled.image(color.image_principale) &&
        color.image_principale[type] &&
        color.image_principale[type].url
      ) {
        return color.image_principale[type]
      }

      return null
    },
    getImageCdn(color) {
      if (
        prismic.isFilled.image(color.override_image) &&
        prismic.isFilled.keyText(color.override_image_cdn)
      ) {
        return color.override_image_cdn
      }

      return color.image_principale_cdn
    },
    async colorEnter(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      if (el.classList.contains('product-card__colors')) {
        const buttons = el.getElementsByClassName('colors__matter')

        gsap.from(buttons, {
          opacity: 0,
          x: 20,
          stagger: {
            ease: 'power1.out',
            amount: 0.2,
          },
          duration: 0.4,
          onComplete() {
            return done()
          },
        })
      } else {
        return gsap.from(el, {
          opacity: 0,
          duration: 0.3,
          onComplete() {
            return done()
          },
        })
      }
    },
    async colorLeave(el, done) {
      const { gsap } = await import('gsap' /* webpackChunkName: 'gsap' */)

      return gsap.to(el, {
        opacity: 0,
        duration: 0.3,
        onComplete() {
          return done()
        },
      })
    },
  },
}
</script>

<style lang="scss">
.product-card--ref-co {
  .product-card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 3svh;
  }

  .product-card-v2__image {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        180deg,
        RGB(56 24 0 / 0) 60%,
        RGB(56 24 0 / 0.4) 100%
      );
    }
  }

  .product-name {
    text-align: center;

    h2 {
      font-size: rem(54px);
      color: var(--tertiary-color);
      margin-bottom: 0;

      @include mq($from: tablet) {
        font-size: rem(72px);
      }
    }

    .determinant {
      display: block;
      font-size: rem(20px);
      line-height: 1.4;
    }
  }

  a {
    color: var(--tertiary-color);
  }

  .product-card__refs {
    --max-height: 93svh;
    --grid-line-height-1-p: min(100vw, var(--max-height));
    --grid-line-height-2-p: min(
      calc(((100vw - var(--spacing) * 0.2) / 2) * 790 / 480),
      var(--max-height)
    );
    --number-of-lines: 1;

    @include mq($from: tablet) {
      --grid-line-height-1-p: min(calc(100vw * 790 / 1600), var(--max-height));
      --grid-line-height-2-p: min(
        calc(((100vw - var(--spacing) * 0.2) / 2) * 790 / 720),
        var(--max-height)
      );
      --grid-line-height-3-p: min(
        calc(((100vw - var(--spacing) * 0.4) / 3) * (790 / 480)),
        var(--max-height)
      );
      --grid-line-height-4-p: min(
        calc(((100vw - var(--spacing) * 0.6) / 4) * (790 / 480)),
        var(--max-height)
      );
    }

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: calc(var(--spacing) * 0.2);
    grid-template-rows: repeat(
      var(--number-of-lines),
      var(--grid-line-height-3-p)
    );
    margin-top: calc(var(--spacing) * 0.2);
  }

  .product-card__ref {
    --grid-column: 1 / 13;
    --grid-row: 1 / 2;
    --grid-column-mobile: 1 / 13;
    --grid-row-mobile: 1 / 2;

    position: relative;
    overflow: hidden;
    grid-column: var(--grid-column-mobile);
    grid-row: var(--grid-row-mobile);

    @include mq($from: tablet) {
      grid-column: var(--grid-column);
      grid-row: var(--grid-row);
      display: var(--display-desktop, block);
    }

    @include mq($until: tablet) {
      display: var(--display-mobile, block);
    }

    img {
      transition: all 0.3s var(--asphalte-animation-function);
    }

    &__image {
      @include mq($until: tablet) {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          inset: 0;
          background: linear-gradient(
            180deg,
            RGB(56 24 0 / 0) 60%,
            RGB(56 24 0 / 0.4) 100%
          );
        }
      }
    }

    &__video {
      pointer-events: none;
    }

    &__content {
      position: absolute;
      inset: auto 0 0;
      transition: all 0.3s var(--asphalte-animation-function);

      .inner {
        padding: calc(var(--spacing) * 0.6);
        transition: all 0.5s var(--asphalte-animation-function);
        color: var(--tertiary-color);

        @include mq($until: tablet) {
          flex-wrap: wrap;
        }

        @include mq($from: tablet) {
          padding: var(--spacing) var(--spacing) calc(var(--spacing) * 0.8);
        }
      }

      .tag {
        position: absolute;
        left: calc(var(--spacing) * 0.4);
        bottom: 100%;

        @include mq($from: tablet) {
          transform: translateY(calc(var(--spacing) * 0.4));
        }

        @include mq($until: tablet) {
          span {
            font-size: var(--extra-small-font-size);
            line-height: 1;
          }
        }
      }
    }

    &__name {
      font-family: var(--secondary-font-family);
      font-size: var(--color-price-font-size);
      line-height: 1;
      transition: border 0.5s var(--asphalte-animation-function);

      @include mq($from: tablet) {
        padding-right: var(--spacing);
        margin-right: var(--spacing);
        border-right: 1px solid var(--tertiary-color);
      }

      @include mq($until: tablet) {
        flex-basis: 90vw;
        display: flex;
        align-items: center;
      }
    }

    &__icon {
      @include mq($from: tablet) {
        margin-left: auto;
      }
    }

    .price {
      transition: color 0.5s var(--asphalte-animation-function);

      .number {
        font-size: var(--color-price-font-size);
        line-height: 1;
      }
    }

    .compare {
      opacity: 0.8;

      @include mq($from: tablet) {
        opacity: 0.6;
      }

      .number {
        text-decoration: line-through;
      }
    }

    &--refco,
    &--video {
      @include on-hover-and-focus {
        .product-card__ref__content > div {
          background: var(--tertiary-color);
          color: var(--secondary-color);
        }

        .price {
          color: var(--secondary-color);
        }

        .product-card__ref__name {
          border-color: var(--secondary-color);
        }

        img {
          transform: scale(1.1);
          transition: all 1.5s var(--asphalte-animation-function);
        }
      }
    }

    &--edito {
      background-color: var(--secondary-color);
      color: var(--yellow-color);
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: rem(250px);

      @include mq($from: tablet) {
        min-height: rem(300px);
      }

      .product-card__ref__content {
        position: relative;
        text-align: center;
        padding: calc(var(--spacing) * 0.6);
        font-family: var(--secondary-font-family);
        font-size: var(--h4-font-size);

        @include mq($from: tablet) {
          font-size: var(--h1-font-size);
          padding: calc(var(--spacing) * 2);
        }
      }
    }

    &--fond-blanc-texte-bleu {
      background-color: var(--tertiary-color);
      color: var(--secondary-color);

      .product-card__ref__content {
        font-family: var(--primary-font-family);
        font-size: var(--base-font-size);
      }
    }

    &--video {
      display: flex;
      background: var(--primary-color);

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.product-card--normal {
    h2 {
      @include mq($until: tablet) {
        font-size: var(--h1-font-size);
      }
    }
  }
}
</style>
