<template>
  <component
    :is="component()"
    :type="!isLink ? 'button' : null"
    :to="isLink ? to : null"
    class="btn-link"
    :class="{
      'btn-link--active': active,
      'btn-link--animate-hover': animateHover,
      'link--underline': !skeleton,
    }"
    @click="$emit('click', $event)"
  >
    <icon v-if="withIcon && iconPosition === 'left'" :name="icon" />
    <span
      :class="{ 'skeleton skeleton--bar': skeleton }"
      :style="skeleton ? 'padding: 0 40px;' : ''"
    >
      <slot />
    </span>
    <icon v-if="withIcon && iconPosition === 'right'" :name="icon" />
  </component>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
  name: 'ButtonLink',
  components: { Icon },
  props: {
    as: {
      type: String,
      default: 'button',
    },
    to: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'arrow-left',
    },
    iconPosition: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value),
    },
    animateHover: {
      type: Boolean,
      default: false,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLink() {
      return this.as === 'link'
    },
  },
  methods: {
    component() {
      return this.isLink ? 'nuxt-link' : 'button'
    },
  },
}
</script>

<style lang="scss">
.btn-link {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-family: var(--primary-font-family);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(var(--spacing) * 0.6);

  > span {
    &::after {
      height: rem(2px);
      border-radius: 2px;
    }
  }

  &--active {
    > span {
      &::after {
        --link-underline-transform: scaleX(1);
        --link-underline-origin: 0% 50%;
      }
    }
  }

  &--animate-hover {
    transition: gap 0.3s var(--asphalte-animation-function);

    @include on-hover-and-focus {
      gap: calc(var(--spacing) * 1);
    }
  }
}
</style>
