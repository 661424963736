<template>
  <div class="product-bar">
    <slider
      v-if="!loading"
      :number-of-children="products.length"
      hide-nav
      :gap="10"
      :width="120"
      :loading-style="{ height: '157px' }"
      with-scroll-bar
    >
      <nuxt-link
        v-for="prismicProduct in products"
        :key="`prismic-product-${prismicProduct.id}`"
        :to="$contextPrismicPath({ type: 'product', uid: prismicProduct.uid })"
        class="p-relative slider__element"
        @click.native="trackProductClick(prismicProduct)"
      >
        <lazy-image
          :image="getImage(prismicProduct)"
          :widths="[120, 240]"
          sizes="120px"
        />
        <p
          class="product-name text-uppercase text-color-tertiary text-center text-secondary h6 mb-0 pl-0-4 pr-0-4 pb-0-8"
          :class="{
            'product-name--long': prismicProduct.name.length >= 30,
          }"
        >
          {{ prismicProduct.name
          }}<span
            v-if="hasLinkedOption(prismicProduct)"
            class="d-block text-small pt-0-2"
            >{{ linkedOption(prismicProduct).label }}</span
          >
        </p>
      </nuxt-link>
    </slider>
    <div v-else class="product-bar__loading">
      <div
        v-for="i in 9"
        :key="i"
        class="loading__item skeleton"
        style="width: 120px; height: 157px"
      ></div>
    </div>
  </div>
</template>

<script>
import * as prismic from '@prismicio/client'
import LazyImage from '@/components/Image/LazyImage'
import Slider from '@/components/slider/Slider'

export default {
  name: 'ProductBar',
  components: {
    LazyImage,
    Slider,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getImage(prismicProduct) {
      if (
        prismicProduct &&
        prismic.isFilled.image(prismicProduct.productBarImage)
      ) {
        return prismicProduct.productBarImage
      }

      if (prismicProduct && prismic.isFilled.image(prismicProduct.image)) {
        return prismicProduct.image
      }

      return null
    },
    hasLinkedOption(prismicProduct) {
      return prismicProduct.linkedProducts.some((l) => l.product_option)
    },
    linkedOption(prismicProduct) {
      return prismicProduct.linkedProducts.find((l) => l.product_option)
    },
    trackProductClick(prismicProduct) {
      this.$track.event('product-bar-click', {
        uid: prismicProduct.uid,
      })
    },
  },
}
</script>

<style lang="scss">
.product-bar {
  .slider__element {
    width: rem(120px);
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: RGB(0 0 0 / 0.1);
      z-index: 1;
      transition: all 0.3s;
    }

    @include on-hover-and-focus {
      &::after {
        opacity: 0;
      }

      .lazy-image {
        transform: scale(1.1);
      }
    }
  }

  .product-name {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 1.05;
    z-index: 2;
    text-shadow: rem(1px) rem(1px) rem(1px) rgba($secondary-color, 0.4);
    transition: all 0.3s;

    &--long {
      font-size: var(--big-font-size);
      line-height: 1.1;
    }
  }
}

.product-bar__loading {
  display: grid;
  gap: rem(10px);
  grid-template-columns: repeat(9, 120px);
  justify-content: center;
}
</style>
