<template>
  <div
    class="slider p-relative"
    :class="{
      'slider--scroll-bar': showScrollBar,
      'slider--autoplay': autoplay,
      'slider--gesture': !noGesture && !withScrollBar,
    }"
    @mousedown="onPointerDown"
    @touchstart="onPointerDown"
  >
    <div ref="inner" class="inner" @scroll="onInnerScroll">
      <div
        v-if="!ready"
        class="slider__inner slider__inner--loading"
        :style="loadingInnerStyle"
      >
        <div
          v-if="numberOnScreen === 1"
          class="skeleton"
          :style="loadingElementStyle"
        ></div>
        <div
          v-for="index in numberOfChildren"
          v-else
          :key="index"
          class="skeleton"
          :style="loadingElementStyle"
        ></div>
      </div>
      <div
        v-else
        class="slider__inner"
        :style="{
          gridGap: `${gap}px`,
          gridTemplateColumns: `repeat(${numberOfChildren}, ${
            width ? width : childWidth
          }px)`,
          marginLeft: withScrollBar ? '' : `${left}px`,
          width: `${maxWidth}px`,
        }"
      >
        <slot />
      </div>
    </div>

    <div class="slider__controls">
      <div
        v-if="autoplay"
        class="slider__autoplay d-flex align-center text-label"
      >
        <p class="mb-0 mr-0-4">01</p>
        <progress-bar
          v-for="i in numberOfChildren"
          :key="i"
          :value="
            i - 1 < sliderPosition
              ? 100
              : i - 1 === sliderPosition
              ? autoplayPercent
              : 0
          "
          :total="100"
          class="ml-0-2 mr-0-2"
          :style="
            backTo0
              ? `--progress-transition-duration: 0.3s; --progress-transition-delay: ${
                  (sliderPosition + 1 - i) * 0.3
                }s`
              : ''
          "
          @click.native="sliderGoTo(i - 1)"
        />
        <p class="mb-0 ml-0-4">0{{ numberOfChildren }}</p>
      </div>

      <slider-nav
        v-if="!hideNav && maxWidth > innerWidth"
        :as-square="navAsSquare"
        :hide-left="hidePrev"
        :hide-right="hideNext"
        @prev="sliderPrev"
        @next="sliderNext"
      />

      <div v-if="pagination" class="slider__number text-secondary">
        <p class="mb-0">
          {{ sliderPosition + 1 }}&nbsp;/&nbsp;{{ numberOfChildren }}
        </p>
      </div>
    </div>

    <div v-if="showScrollBar" class="scrollbar">
      <div
        ref="scrollbar"
        class="bar"
        :style="`--bar-width: ${scrollbarWidth}%; --bar-left: ${scrollbarLeft}%;`"
      >
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import SliderNav from '@/components/slider/SliderNav'
import ProgressBar from '@/components/progressbar/ProgressBar'

export default {
  name: 'Slider',
  components: {
    SliderNav,
    ProgressBar,
  },
  props: {
    loadingStyle: { type: Object, default: () => {} },
    loadingWidth: { type: Number, default: null },
    navAsSquare: { type: Boolean, default: false },
    hideNav: { type: Boolean, default: false },
    numberOfChildren: { type: Number, required: true },
    numberOnScreen: { type: Number, default: 0 },
    gap: { type: Number, default: 30 },
    width: { type: Number, default: null },
    maxChildWidth: { type: Number, default: null },
    withScrollBar: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false },
    autoplayTime: { type: Number, default: 1000 },
    noGesture: { type: Boolean, default: false },
    pagination: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
  },
  data: () => ({
    ready: false,
    sliderPosition: 0,
    sliderPositionFromSwipe: 0,
    innerWidth: 0,
    posX: 0,
    initialScrollLeft: 0,
    diffX: 0,
    lastDiffX: 0,
    hasMoved: false,
    autoplayInterval: null,
    autoplayTick: 0,
    autoplayRefreshTime: 200,
    backTo0: false,
    innerEl: null,
    scrollbarLeft: 0,
    pointerIsDown: false,
  }),
  computed: {
    loadingInnerStyle() {
      const ss = {
        gridGap: `${this.gap}px`,
      }

      if (this.width) {
        ss.gridTemplateColumns = `repeat(${this.numberOfChildren}, ${this.width}px)`
      }

      if (this.loadingWidth) {
        ss.gridTemplateColumns = `repeat(${this.numberOfChildren}, ${this.loadingWidth}px)`
      }

      return ss
    },
    loadingElementStyle() {
      const ss = {
        ...this.loadingStyle,
      }

      if (this.width) {
        ss.width = `${this.width}px`
      }

      if (this.loadingWidth) {
        ss.width = `${this.loadingWidth}px`
      }

      return ss
    },
    sliderMaxPosition() {
      return this.numberOfChildren - this.numberOnScreen
    },
    childWidth() {
      let w

      if (this.width) {
        return this.width
      }

      if (this.numberOnScreen === 1) {
        w = this.innerWidth / this.numberOnScreen
      }

      w =
        this.innerWidth / this.numberOnScreen -
        (this.gap * (this.numberOnScreen - 1)) / this.numberOnScreen

      if (this.maxChildWidth && w > this.maxChildWidth) {
        return this.maxChildWidth
      }

      return w
    },
    maxWidth() {
      return (
        this.childWidth * this.numberOfChildren +
        this.gap * (this.numberOfChildren - 1)
      )
    },
    maxLeft() {
      return Math.abs(this.maxWidth - this.innerWidth)
    },
    left() {
      let left = -(
        this.sliderPosition * (this.childWidth + this.gap) +
        this.lastDiffX
      )

      if (Math.abs(this.diffX) > 20) {
        left -= this.diffX
      }

      if (this.numberOnScreen === 1 && this.childWidth < this.innerWidth) {
        left += (this.innerWidth - this.childWidth) / 2
      }

      if (left > 0) {
        return 0
      }

      if (Math.abs(left) > this.maxLeft) {
        return -this.maxLeft
      }

      return left
    },
    autoplayPercent() {
      return (this.autoplayTick / this.autoplayTime) * 100
    },
    hidePrev() {
      if (this.loop) {
        return false
      }

      return !this.autoplay && this.sliderPosition === 0 && this.left === 0
    },
    hideNext() {
      if (this.loop) {
        return false
      }

      return !this.autoplay && this.sliderPosition === this.sliderMaxPosition
    },
    showScrollBar() {
      return this.withScrollBar && this.maxWidth > this.innerWidth
    },
    scrollbarWidth() {
      return (this.innerWidth / this.maxWidth) * 100
    },
  },
  beforeDestroy() {
    document.removeEventListener('touchmove', this.onPointerMove)
    document.removeEventListener('touchend', this.onPointerUp)
    document.removeEventListener('mousemove', this.onPointerMove)
    document.removeEventListener('mouseup', this.onPointerUp)
    document.removeEventListener('resize', this.initSlider)

    clearInterval(this.autoplayInterval)
  },
  mounted() {
    this.initSlider()

    document.addEventListener('resize', this.initSlider)

    if (this.autoplay) {
      this.play()
    }
  },
  methods: {
    initSlider() {
      const styles = getComputedStyle(this.$refs.inner)
      this.innerWidth =
        this.$refs.inner.offsetWidth -
        (parseInt(styles.paddingLeft) + parseInt(styles.paddingRight))

      this.inner = this.$refs.inner.getElementsByClassName('slider__inner')[0]
      this.ready = true

      if (this.showScrollBar && this.$refs.scrollbar) {
        this.onInnerScroll()
      }

      this.$emit('change', this.sliderPosition)
    },
    autoplayIntervalTick() {
      if (this.autoplayTick >= this.autoplayTime) {
        this.sliderNext(false)
        this.autoplayTick = 0
        return
      }

      if (this.autoplayTick > 0) {
        this.backTo0 = false
      }

      this.autoplayTick += this.autoplayRefreshTime
    },
    sliderPrev(fromUserAction = true) {
      this.lastDiffX = 0

      if (this.autoplay && fromUserAction) {
        this.autoplayTick = 0
        this.stop()
      }

      if (this.sliderPosition !== this.sliderPositionFromSwipe) {
        this.sliderPosition = this.sliderPositionFromSwipe
      }

      if (this.sliderPosition === 0) {
        if (this.autoplay || this.loop) {
          this.sliderPosition = this.sliderMaxPosition
          this.sliderPositionFromSwipe = this.sliderMaxPosition
        }

        this.$emit('change', this.sliderPosition)
        return
      }

      this.backTo0 = false

      this.sliderPosition--
      this.sliderPositionFromSwipe = this.sliderPosition
      this.$emit('change', this.sliderPosition)
    },
    sliderNext(fromUserAction = true) {
      this.lastDiffX = 0

      if (this.autoplay && fromUserAction) {
        this.autoplayTick = 0
        this.stop()
      }

      if (this.sliderPosition !== this.sliderPositionFromSwipe) {
        this.sliderPosition = this.sliderPositionFromSwipe
      }

      if (this.sliderPosition === this.sliderMaxPosition) {
        if (this.autoplay || this.loop) {
          this.backTo0 = true
          this.sliderPosition = 0
          this.sliderPositionFromSwipe = 0
        }

        this.$emit('change', this.sliderPosition)
        return
      }

      this.backTo0 = false

      this.sliderPosition++
      this.sliderPositionFromSwipe = this.sliderPosition

      this.$emit('change', this.sliderPosition)
    },
    sliderGoTo(index) {
      this.lastDiffX = 0

      if (this.autoplay) {
        this.autoplayTick = 0
      }

      this.sliderPosition = index
      this.sliderPositionFromSwipe = index
      this.$emit('change', this.sliderPosition)
    },

    onPointerDown(ev) {
      if (this.noGesture || !this.ready) {
        return
      }

      if (!this.withScrollBar && ev.type === 'touchstart') {
        this.posX = ev.touches[0].clientX
        document.addEventListener('touchmove', this.onPointerMove, {
          passive: false,
        })
        document.addEventListener('touchend', this.onPointerUp, {
          passive: false,
        })
      } else if (ev.type === 'mousedown') {
        ev.preventDefault()
        this.posX = ev.clientX

        if (this.withScrollBar) {
          this.initialScrollLeft = this.$refs.inner.scrollLeft
        }

        document.addEventListener('mousemove', this.onPointerMove)
        document.addEventListener('mouseup', this.onPointerUp)
      }

      this.inner.classList.add('no-animate')
      this.$el.classList.add('show-scrollbar')
    },

    onPointerMove(ev) {
      if (ev.type === 'touchmove') {
        this.diffX = this.posX - ev.touches[0].clientX
      } else {
        this.diffX = this.posX - ev.clientX

        if (this.withScrollBar) {
          this.$refs.inner.scrollLeft = this.initialScrollLeft + this.diffX
        }
      }

      if (Math.abs(this.diffX) > 30) {
        if (ev.cancelable) ev.preventDefault()
        this.hasMoved = true
        this.inner.classList.add('moving')
      }

      this.inner.style.cursor = 'grabbing'
    },

    onPointerUp(ev) {
      if (this.hasMoved) {
        if (ev.cancelable) ev.preventDefault()

        this.hasMoved = false

        if (this.numberOnScreen === 1) {
          let diffX
          if (ev.type === 'touchend') {
            diffX = this.posX - ev.changedTouches[0].clientX
          } else {
            diffX = this.posX - ev.clientX
          }

          this.diffX = 0
          this.lastDiffX = 0

          if (diffX < 0) {
            this.$track.event('slider_swipe', { direction: 'prev' })
            this.sliderPrev()
          } else {
            this.$track.event('slider_swipe', { direction: 'next' })
            this.sliderNext()
          }
        } else {
          this.$track.event('slider_swipe', {
            direction: this.diffX < 0 ? 'prev' : 'next',
          })
          this.lastDiffX += this.diffX
          this.diffX = 0
          this.updateSliderSwipePosition()
        }
      } else {
        this.diffX = 0
      }

      this.inner.classList.remove('no-animate', 'moving')
      this.inner.style.cursor = ''
      this.$el.classList.remove('show-scrollbar')

      document.removeEventListener('touchmove', this.onPointerMove)
      document.removeEventListener('touchend', this.onPointerUp)

      document.removeEventListener('mousemove', this.onPointerMove)
      document.removeEventListener('mouseup', this.onPointerUp)
    },

    updateSliderSwipePosition() {
      this.sliderPositionFromSwipe = Math.round(
        Math.abs(this.left / (this.childWidth + this.gap))
      )
    },

    onInnerScroll() {
      if (this.showScrollBar) {
        this.scrollbarLeft = (this.$refs.inner.scrollLeft / this.maxWidth) * 100
      }
    },

    play() {
      if (this.autoplayInterval === null) {
        this.autoplayInterval = setInterval(() => {
          this.autoplayIntervalTick()
        }, this.autoplayRefreshTime)
      }
    },

    stop() {
      clearInterval(this.autoplayInterval)
      this.autoplayInterval = null
    },
  },
}
</script>

<style lang="scss">
.slider {
  &--gesture {
    touch-action: pan-y;
  }

  &__inner {
    display: grid;
    will-change: margin;
    transition: margin 0.3s;
    margin: auto;

    &--loading {
      overflow: hidden;
    }

    &.moving {
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
      }
    }
  }

  &__autoplay {
    --progress-transition-duration: 0.3s;
    --progress-transition-delay: 0s;

    color: var(--secondary-color);
    max-width: calc(100% - #{rem(64px)});

    @include mq($until: tablet) {
      padding-top: var(--spacing);
    }

    @include mq($from: tablet) {
      position: absolute;
      bottom: calc(var(--spacing) * 2);
      right: calc(var(--spacing) * 2);
      z-index: 2;
      color: var(--tertiary-color);
    }

    .progress-bar {
      border-radius: 20px;
      background: rgba($secondary-color-3, 0.6);
      height: rem(2px);
      overflow: hidden;
      transition: all 0.3s;

      @include mq($until: tablet) {
        flex: 0 1 rem(30px);
      }

      @include mq($from: tablet) {
        width: rem(30px);
      }

      &__value {
        transition: width 0.3s linear;
        transition-duration: var(--progress-transition-duration);
        transition-delay: var(--progress-transition-delay);

        @include mq($from: tablet) {
          background: var(--tertiary-color);
        }
      }

      @include on-hover-and-focus {
        background: rgba($secondary-color-3, 1);
      }
    }

    @include on-hover-and-focus {
      .progress-bar {
        height: rem(6px);
      }
    }
  }

  &__number {
    position: absolute;
    color: var(--tertiary-color);
    z-index: 3;

    @include mq($until: tablet) {
      left: 50%;
      transform: translateX(-50%);
      bottom: rem($spacing);
    }

    @include mq($from: tablet) {
      left: rem($spacing * 2);
      bottom: rem($spacing * 1.5);
    }
  }

  .slider-nav--as-square {
    @include mq($from: tablet, $until: large) {
      .slider-nav__prev {
        left: calc(100% / #{$grid-columns});
      }

      .slider-nav__next {
        right: calc(100% / #{$grid-columns});
      }
    }

    @include mq($from: tablet) {
      .slider-nav__prev {
        transform: translate(-75%, -50%);
      }

      .slider-nav__next {
        transform: translate(75%, -50%);
      }
    }
  }

  &--scroll-bar {
    .inner {
      overflow: auto;
      padding-bottom: var(--spacing);
      scrollbar-width: none;
    }

    .scrollbar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: rem(10px);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s 0.5s;

      .bar {
        position: absolute;
        width: var(--bar-width);
        left: var(--bar-left, 0);
        bottom: rem(2px);
        height: rem(6px);
        border-radius: 4px;
        background-color: var(--secondary-color);
      }
    }

    @include on-hover-and-focus {
      .scrollbar {
        opacity: 1;
        transition: opacity 0.3s 0s;
      }
    }

    &.show-scrollbar {
      .scrollbar {
        opacity: 1;
        transition: opacity 0.3s 0s;
      }
    }
  }

  &--autoplay {
    @include mq($until: tablet) {
      .slider__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .slider-nav {
        display: flex;
        align-items: center;
        margin-right: calc(var(--spacing) * -0.6);

        .action {
          position: relative;
          padding-bottom: 0;
          padding-left: calc(var(--spacing) * 0.6);
          padding-right: calc(var(--spacing) * 0.6);

          svg {
            width: rem(16px);
            height: rem(16px);
          }
        }

        .action__inner {
          color: var(--secondary-color);
        }
      }
    }
  }

  &--no-transition {
    .slider__inner {
      transition: none;
    }
  }
}
</style>
