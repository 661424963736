<template>
  <div
    class="product-card-v2 product-card-v2--grid"
    :class="{
      'is-first': isFirst,
      'product-card-v2--full': typeDesktop === 'one-per-line',
    }"
  >
    <component
      :is="mainComponent()"
      :to="
        $contextPrismicPath({
          type: 'collection',
          uid: `stock-${$route.params.context}`,
        })
      "
    >
      <div class="product-card-v2__image">
        <transition mode="in-out" name="product-card-image-switch">
          <lazy-image
            v-if="images.length > 0"
            :images="images"
            :preload="isFirst"
            :force-show="isFirst"
            provider="shopify"
          />
        </transition>
      </div>

      <div class="product-card-v2__content text-color-tertiary">
        <div class="inner">
          <div
            class="d-flex"
            :class="{
              'justify-center': !isFirst,
            }"
          >
            <div v-if="isFirst">
              <p class="lh-1 text-md-base text-left mb-0-8">
                {{
                  $t('lauch_day', {
                    day: $d(shopifyProduct.dates.start, 'weekday'),
                  })
                }}
              </p>
            </div>
            <div v-else class="mb-1">
              <marketing-argument
                :dates="shopifyProduct.dates"
                aspect="yellow-and-secondary"
              />
            </div>
          </div>

          <p
            class="product-card-v2__name text-secondary mb-0-2"
            :class="{
              'text-center': !isFirst,
            }"
          >
            {{ $t('stock.title') }}
          </p>

          <div v-if="isTeasingMode" />
          <p v-else-if="isFirst" class="d-none d-md-block lh-1-2 pb-md-0-4">
            <span class="d-block">{{ $t('stock.alert.1') }}</span>
            <span class="d-block">{{ $t('stock.alert.2') }}</span>
          </p>
          <p v-else class="text-small text-center lh-1-2 pt-0-2">
            {{ $t('stock.punchline') }}
          </p>

          <div
            v-if="isTeasingMode"
            class="pt-0-6 pb-md-0-4 mb-0-4 mb-md-1 d-flex justify-center"
          >
            <primary-button
              type="button"
              :text="$t('stock.teasing_action')"
              with-arrow
            />
          </div>
          <div
            v-else-if="isFirst && $vssWidth > 796"
            class="w-100 pb-md-0-4 mb-0-4 mb-md-1"
          >
            <create-alert
              :product="{
                productSlug: createAlertSlug,
                productName: '',
                productId: shopifyProduct.id,
                productImage: 'https://',
                location: origin,
              }"
              :origin="origin"
              button-size="primary"
              inline
              @has-subscribe="hasSubscribe()"
              @has-unsubscribe="hasUnSubscribe()"
            />
          </div>
          <div
            v-else
            class="d-flex pt-0-4 pb-0-4 mb-0-6 mb-md-1"
            :class="{
              'justify-center': !isFirst,
            }"
          >
            <button
              class="product-card-v2__agenda text-color-tertiary w-600"
              @click.prevent="onAlertClick($event)"
            >
              <span class="d-flex align-center link--underline">
                <icon name="bell" :full="isSubscribed" />
                <span class="d-block ml-0-4">{{
                  $t(
                    isSubscribed
                      ? 'alert.tooltip.remove'
                      : 'alert.tooltip.create'
                  )
                }}</span>
              </span>
            </button>
          </div>
        </div>

        <div
          v-if="isFirst"
          class="product-card-v2__countdown d-flex d-md-block flex-direction-column align-center mt-1 mt-md-0"
        >
          <div class="d-flex justify-center justify-md-end flex-grow-1 mb-0-6">
            <div v-if="isSellsAreOpen" class="tag tag--sun-and-white">
              <icon name="estimated-time" />
              <span>{{ $t('product.tag.last_before_end') }}</span>
            </div>
            <div v-else class="tag tag--yellow-and-secondary">
              <icon name="calendar-2" />
              <span>{{ $t('product.tag.soon_preco') }}</span>
            </div>
          </div>

          <big-count-down
            v-if="isSellsAreOpen"
            :date="shopifyProduct.dates.end"
            home
          />
          <big-count-down v-else :date="shopifyProduct.dates.start" />
        </div>
      </div>

      <transition name="from-bottom" @after-enter="afterCreateAlertEnter">
        <create-alert
          v-if="isAlertOpen"
          id="alert-stock"
          :product="{
            productSlug: createAlertSlug,
            productName: '',
            productId: shopifyProduct.id,
            productImage: 'https://',
            location: origin,
          }"
          :title="
            $t('stock.alert.highlight_title', {
              date: $d(shopifyProduct.dates.start, 'short'),
            })
          "
          :text="$t('stock.alert.receive_alert')"
          :origin="origin"
          button-size="primary"
          with-close
          @has-subscribe="hasSubscribe()"
          @has-unsubscribe="hasUnSubscribe()"
          @close="isAlertOpen = false"
        />
      </transition>
    </component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import NuxtSSRScreenSize from 'nuxt-ssr-screen-size'
import LazyImage from '@/components/Image/LazyImage'
import Icon from '@/components/icons/Icon'
import PrimaryButton from '@/components/buttons/Primary'
import DefaultDiv from '@/components/div/Default'

import MarketingArgument from '@/components/Product/MarketingArgument.v2'

import { ORIGIN } from '@/const'
import { EVENTS } from '@/const/events'

export default {
  name: 'StockCard',
  components: {
    MarketingArgument,
    LazyImage,
    Icon,
    PrimaryButton,
    DefaultDiv,
    CreateAlert: () =>
      import(
        '@/components/Product/CreateAlert' /* webpackChunkName: 'product_create_alert' */
      ),
    BigCountDown: () =>
      import(
        '@/components/countdown/BigCountDown' /* webpackChunkName: 'big_countdown' */
      ),
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  props: {
    shopifyProduct: {
      type: Object,
      required: true,
    },
    typeMobile: {
      type: String,
      default: 'one-per-line',
    },
    typeDesktop: {
      type: String,
      default: 'one-per-line',
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    home: { type: Boolean, default: false },
  },
  data: () => ({
    isAlertOpen: false,
    isSubscribed: false,
    origin: ORIGIN.AGENDA,
  }),
  computed: {
    ...mapGetters({
      connectedEmail: 'subscriptions/connectedEmail',
      account: 'subscriptions/account',
      isSubscriptionsLoading: 'subscriptions/isLoading',
    }),
    createAlertSlug() {
      return `stock-${this.$d(
        this.shopifyProduct.dates.start,
        'day'
      )}-${this.$d(this.shopifyProduct.dates.start, 'month')}-${this.$d(
        this.shopifyProduct.dates.start,
        'year'
      )}${this.$route.params.context === 'f' ? '-f' : ''}`
    },
    images() {
      const imgs = []

      imgs.push({
        image: {
          url: this.shopifyProduct.image.list,
          url_2x: this.shopifyProduct.image.list_2x,
          dimensions: { width: 480, height: 790 },
        },
        media: {
          type: 'max',
          value: 769,
        },
        sizes: '100vw',
      })

      if (this.typeDesktop === 'three-per-line') {
        imgs.push({
          image: {
            url: this.shopifyProduct.image.list,
            url_2x: this.shopifyProduct.image.list_2x,
            dimensions: { width: 480, height: 790 },
          },
          media: {
            type: 'min',
            value: 770,
          },
          sizes: '33vw',
        })
      } else if (this.typeDesktop === 'two-per-line') {
        imgs.push({
          image: {
            url: this.shopifyProduct.image.half,
            url_2x: this.shopifyProduct.image.half_2x,
            dimensions: { width: 720, height: 790 },
          },
          media: {
            type: 'min',
            value: 770,
          },
          sizes: '50vw',
        })
      } else if (this.typeDesktop === 'one-per-line') {
        imgs.push({
          image: {
            url: this.shopifyProduct.image.full,
            url_2x: this.shopifyProduct.image.full_2x,
            dimensions: { width: 1600, height: 790 },
          },
          media: {
            type: 'min',
            value: 770,
          },
          sizes: '100vw',
        })
      }

      return imgs
    },
    isTeasingMode() {
      if (
        this.shopifyProduct.dates.teasing &&
        this.shopifyProduct.dates.start
      ) {
        const now = new Date()
        return (
          now >= this.shopifyProduct.dates.teasing &&
          now < this.shopifyProduct.dates.start
        )
      }
      return false
    },
    isSellsAreOpen() {
      const now = new Date()
      return (
        now > this.shopifyProduct.dates.start &&
        now < this.shopifyProduct.dates.end
      )
    },
    isLink() {
      return this.isTeasingMode || this.isSellsAreOpen
    },
  },
  mounted() {
    if (
      this.connectedEmail === null ||
      (this.connectedEmail !== '' && this.account.email === null)
    ) {
      this.$nuxt.$on(EVENTS.USER_STATE_LOADED, (_) => {
        this.isSubscribed = this.account.isSubscribed(this.createAlertSlug)
      })
    } else {
      this.isSubscribed = this.account.isSubscribed(this.createAlertSlug)
    }
  },
  methods: {
    ...mapActions({
      subscribe: 'subscriptions/subscribe',
      unsubscribe: 'subscriptions/unsubscribe',
    }),
    mainComponent() {
      if (!this.isLink) {
        return DefaultDiv
      }

      return 'nuxt-link'
    },
    onAlertClick(ev) {
      ev.stopPropagation()

      const product = {
        productSlug: this.createAlertSlug,
        productName: '',
        productId: this.shopifyProduct.id,
        productImage: 'https://',
        location: this.origin,
      }

      if (this.isSubscribed) {
        return this.unsubscribe(product)
          .then(() => {
            this.isSubscribed = this.account.isSubscribed(this.createAlertSlug)

            this.$track.bisUnSubscribe(this.createAlertSlug, this.origin)

            this.showToaster(`<div>
                ${this.$t('stock.alert.deactivated', {
                  date: this.$d(this.shopifyProduct.dates.start, 'short'),
                })}
              </div>
              `)
          })
          .catch((err) => {
            this.$logError.captureException(err)
            this.subscribeError = true
          })
      }

      if (this.connectedEmail === '') {
        this.isAlertOpen = !this.isAlertOpen

        return
      }

      return this.subscribe(product)
        .then(() => {
          this.isSubscribed = this.account.isSubscribed(this.createAlertSlug)

          this.$nuxt.$emit(EVENTS.ASK_NOTIFICATION, this.origin)
          this.$track.bisSubscribe(
            this.account.email,
            this.createAlertSlug,
            this.origin
          )

          this.showToaster(`<div>
                ${this.$t('stock.alert.activated', {
                  date: this.$d(this.shopifyProduct.dates.start, 'short'),
                  email: this.account.email,
                })}
              </div>
              `)
        })
        .catch((err) => {
          this.$logError.captureException(err)
          this.subscribeError = true
        })
    },
    showToaster(text) {
      this.$toasted.clear()
      this.$toasted.show(text, {
        position: 'bottom-center',
        duration: 5000,
        keepOnHover: true,
        action: {
          text: '',
          icon: 'close',
          class: 'toasted__close',
          onClick: (_, toastObject) => {
            toastObject.goAway(0)
          },
        },
      })
    },
    hasSubscribe() {
      this.isAlertOpen = false
      this.isSubscribed = this.account.isSubscribed(this.createAlertSlug)
    },
    hasUnSubscribe() {
      this.isAlertOpen = false
      this.isSubscribed = this.account.isSubscribed(this.createAlertSlug)
    },
    afterCreateAlertEnter() {
      document.getElementById(`alert-stock-bis-email`).focus()
    },
  },
}
</script>
