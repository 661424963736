<template>
  <div
    v-waypoint="{
      active: true,
      callback: onWaypoint,
      options: intersectionOptions,
    }"
    style="
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    "
  >
    <iframe
      ref="video"
      :src="src"
      loading="lazy"
      style="
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      "
      allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
      allowfullscreen="true"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'VideoBunny',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    player: null,
    playerIsReady: false,
    actionToWait: null,
    intersectionOptions: {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1],
    },
  }),
  mounted() {
    if (!document.getElementById('bunny-player')) {
      const tag = document.createElement('script')
      tag.src = '//assets.mediadelivery.net/playerjs/player-0.1.0.min.js'
      tag.id = 'bunny-player'
      tag.async = true
      tag.onload = () => {
        this.initBunnyPlayer()
      }
      document.body.appendChild(tag)
    } else {
      this.initBunnyPlayer()
    }
  },
  methods: {
    initBunnyPlayer() {
      if (this.$refs.video) {
        this.player = new playerjs.Player(this.$refs.video)
      } else {
        return console.warn('no video ref')
      }

      this.player.on('ready', (_) => {
        if (this.actionWait) {
          this.player[this.actionWait]()
          this.actionWait = null
        }
        this.playerIsReady = true
      })
    },
    onWaypoint(waypoint) {
      if (waypoint.going === this.$waypointMap.GOING_OUT) {
        this.screenOut()
      }

      if (waypoint.going === this.$waypointMap.GOING_IN && !this.inScreen) {
        this.screenIn()
      }
    },
    screenIn() {
      if (this.playerIsReady) {
        this.player.play()
      } else {
        this.actionWait = 'play'
      }
    },
    screenOut() {
      if (this.playerIsReady) {
        this.player.pause()
      } else {
        this.actionWait = 'pause'
      }
    },
  },
}
</script>
